<template>
  <div>
    <v-text-field
      v-mask="'#-####-#####-##-#'"
      :masked="false"
      :value="value"
      type="tel"
      solo
      clearable
      required
      clear-icon="mdi-close-circle"
      @click:clear="$emit('input',null)"
      :hide-details="errors.length ? false : true"
      :error-messages="errors"
      prepend-inner-icon="mdi-card-text"
      placeholder="x-xxxx-xxxxx-xx-x"
      @input="onInput"
    ></v-text-field>
  </div>
</template>

<script>
  export default {
    props: ['value','errors'],
    data() {
      return {
      }
    },
    methods:{
      onInput(event) {
        if(event) {
          let id_card = event.replace(/-/g, "");
          this.$emit("input", id_card);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>