<template>
  <div class="wrapper">
    <div class="wrapper-login-box">
      <div class="logo-image"></div>
      <div class="login-input-box">
        <ValidationObserver ref="observer">
          <div>
            <label class="label-text">เลขบัตรประชาชน</label>
            <ValidationProvider
              name="เลขบัตรประชาชน"
              :rules="'required|digits: 13'"
              v-slot="{ errors }"
            >
              <ThaiNationalId
                v-model="input.citizen_id"
                :errors="errors"
              ></ThaiNationalId>
            </ValidationProvider>
          </div>
          <div class="mt-5">
            <label class="label-text">เบอร์โทรศัพท์</label>
            <ValidationProvider
              name="เบอร์โทรศัพท์"
              :rules="'required|thai_phonenumber'"
              v-slot="{ errors }"
            >
              <ThaiPhoneNumber
                v-model="input.phone_number"
                :errors="errors"
              ></ThaiPhoneNumber>
            </ValidationProvider>
          </div>
          <div class="mt-9">
            <vue-recaptcha
              ref="recaptcha"
              :key="`recaptcha_${timestamp}`"
              @verify="login"
              @expired="onExpired"
              :sitekey="sitekey"
            >
              <v-btn
                :loading="loading"
                color="#0325A0"
                width="100%"
                height="48px"
                style="font-size: 18px"
                dark
                >เข้าสู่ระบบ</v-btn
              >
            </vue-recaptcha>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ThaiNationalId from "@/components/ThaiNationalId.vue";
import ThaiPhoneNumber from "@/components/ThaiPhoneNumber.vue";
import { VueRecaptcha } from "vue-recaptcha";
import moment from "moment";
import constants from "@/constants/index.js";

export default {
  components: {
    ThaiNationalId,
    ThaiPhoneNumber,
    VueRecaptcha,
  },
  data() {
    return {
      input: {
        citizen_id: "",
        phone_number: "",
        test: "",
      },
      loading: false,
      rememberme: false,
      recaptcha: undefined,
      timestamp: moment().format("x"),
      sitekey: constants.googleRecaptchaSiteKey,
    };
  },
  beforeCreate() {
    this.$store.state.bgcolor = "#efefef";
  },
  beforeDestroy() {
    this.$store.state.bgcolor = "white";
  },
  methods: {
    async login(response) {
      let vm = this;
      await vm.$refs.observer.validate().then((result) => {
        if (!result) {
          vm.resetRecaptcha();
          return false;
        }

        vm.$store.state.recaptchaToken = response;
        vm.$refs.recaptcha.execute();
        vm.onSubmit(response);
      });
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.timestamp = moment().format("x");
    },
    onExpired: function () {},
    async onSubmit(token) {
      if (await this.$refs.observer.validate()) {
        const vm = this;
        let data = {
          pageId: vm.$route.query.pageid,
          userId: vm.$route.query.userid,
          citizenId: vm.input.citizen_id,
          phoneNumber: vm.input.phone_number,
          app: vm.$route.query.apptype,
          gpf_user_id: vm.$route.query.userid,
          "g-recaptcha-response": token,
        };
        vm.loading = true;

        axios
          .post(
            `https://api-enterprise.zwiz.app/government-pension-fund/facebook/phone`,
            data,
            {
              headers: {
                AUTHORIZATION: "Bearer xxx xxx",
              },
            }
          )
          .then(async (response) => {
            vm.loading = false;
            vm.$store.state.registerData = response.data.data;
            const query = this.$route.query;
            vm.$router.push(
              `verification?apptype=${query.apptype}&userid=${query.userid}&pageid=${query.pageid}`
            );
          })
          .catch(function (error) {
            vm.loading = false;
            if (error.response) {
              vm.$swal.fire({
                icon: "error",
                text: error.response.data.reason,
                confirmButtonColor: "#0325A0",
                confirmButtonText: "ปิด",
                width: 350,
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-login-box {
  width: 100%;
  max-height: 500px;
  padding: 0 32px 100px;
  .logo-image {
    background-image: url("../assets/logo.png");
    height: 71px;
    background-size: contain;
    width: 200px;
    margin: 0 auto;
  }
  .login-input-box {
    padding-top: 64px;
    .label-text {
      color: #fff;
      font-size: 14px;
      text-align: left;
    }
  }
}
</style>
